import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import uius from '@maersk96/voui'

import Icon from '@/uius/components/atoms/UiusIcon.vue'
import Button from '@/uius/components/form/UiusButton.vue'

const app = createApp(App)
app.use(router)

app.use(uius)

// Global components
app.component('Icon', Icon)
app.component('Button', Button)



// Mount app
app.mount('#app')