import { reactive, computed } from 'vue'

export interface Crop {
  file: File
  canvas: HTMLCanvasElement | null
  name: string
}

export type Dimensions = { height: number, width: number }

export interface CropperOptions {
  margin: number
  size: Dimensions
  aspect: string // x:y
  scale: number
  background: string
  customBackground: string
  sizingRule: 'fixed_width' | 'fixed_height' | 'preserve_pixels' | 'megapixel_limit' 
  sizingOptions: {
    fixedWidth: number
    fixedHeight: number
    megapixelLimit: number
  }
  imageFit: 'cover' | 'contain' | 'preserve_pixels'
}

export const ratio = (size: Dimensions) => {
  return size.width / size.height
}

export const useOverwrites = <T extends object>(object: T) => {
  const overwrites = reactive<Partial<T>>({})

  const reset = (key: keyof T) => {
    delete overwrites[key]
  }
  const options = computed<T>(() => ({ ...object, ...overwrites }))

  return { reset, overwrites, options }
}