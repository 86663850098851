import { ref } from 'vue'

export const useWindowScroll = () => {
  const x = ref(window.pageXOffset)
  const y = ref(window.pageYOffset)

  document.addEventListener('scroll', () => {
    x.value = window.pageXOffset
    y.value = window.pageYOffset
  })

  return { x, y }
}